import React, { useState, useContext } from "react"
import "typeface-montserrat"
import "typeface-open-sans"
import "../styles/global.css"
import { elastic as Menu } from "react-burger-menu"
import { useForm } from "react-hook-form"
import axios from "axios"
import loadable from "@loadable/component"
import logoBG from "../imgs/logoBG.png"
import logoFundoPreto from '../imgs/logoFundoPreto.png'
import location from "../imgs/location.svg"
import contacts from "../imgs/contacts.svg"
import tel from "../imgs/tel.svg"
import email from "../imgs/email.svg"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import { Helmet } from 'react-helmet'

const ScrollTop = loadable(() => import("@nzambello/react-scrolltop"))

export default function Home(props) {
  const iframe = {
    __html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.219451087499!2d-35.699953650248744!3d-9.662281593049212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70145aad87085fd%3A0x463ad05e7f62d27c!2sEdif%C3%ADcio%20Empresarial%20Leonardo%20da%20Vinci%20-%20R.%20Durval%20Guimar%C3%A3es%2C%201217%20-%20Ponta%20Verde%2C%20Macei%C3%B3%20-%20AL%2C%2057035-060!5e0!3m2!1spt-BR!2sbr!4v1597884157689!5m2!1spt-BR!2sbr" width="90%" height="400px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
  }

  const { register, handleSubmit, watch, errors } = useForm()

  const onSubmit = data => {
    axios
      .post("https://getform.io/f/d3cc8014-e4b2-4ab3-bf08-9f1e5a71faf4", data, {
        headers: { Accept: "application/json" },
      })
      .then(function (response) {
        console.log(response)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const MyContext = React.createContext()

  const MyProvider = props => {
    const [menuOpenState, setMenuOpenState] = useState(false)

    return (
      <MyContext.Provider
        value={{
          isMenuOpen: menuOpenState,
          toggleMenu: () => setMenuOpenState(!menuOpenState),
          stateChangeHandler: newState => setMenuOpenState(newState.isOpen),
        }}
      >
        {props.children}
      </MyContext.Provider>
    )
  }

  const ColorButton = withStyles(() => ({
    root: {
      color: "var(--main-claro)",
      backgroundColor: "var(--main-escuro)",
      padding: "1rem 0",
      margin: "4rem 0",
      width: "30%",
      alignSelf: "center",
    },
  }))(Button)

  const MenuInicial = () => {
    return (
      <ul id="menu">
        <li>
          <a href="#escritorio">ESCRITÓRIO</a>
        </li>
        <li>
          <a href="#socios">SÓCIOS</a>
        </li>
        <li>
          <a href="#contato">CONTATO</a>
        </li>
      </ul>
    )
  }

  const Navigation = () => {
    const ctx = useContext(MyContext)
    return (
      <Menu
        isOpen={ctx.isMenuOpen}
        onStateChange={state => ctx.stateChangeHandler(state)}
      >
        <a href="#escritorio" className="menu-item">
          ESCRITÓRIO
        </a>
        <a href="#socios" className="menu-item">
          SÓCIOS
        </a>
        <a href="#contato" className="menu-item">
          CONTATO
        </a>
      </Menu>
    )
  }

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta property='og:image' content={logoFundoPreto} />
        <meta name='description' content='Campos & Farias - Advocacia & Consultoria' />
        <title>Campos & Farias - Advocacia & Consultoria</title>
      </Helmet>
      <ScrollTop />
      <Box bgcolor="var(--main-escuro)" m={0}>
        <Box justifyContent="center" id="inicio">
          <MyProvider>
            <Navigation />
          </MyProvider>
          <Hidden smDown>
            <MenuInicial />
          </Hidden>
          <img id="logoMain" src={logoBG} alt="Logo" />
        </Box>
      </Box>
      <Box
        bgcolor="var(--main-claro)"
        color="var(--main-escuro)"
        py={4}
        mx="auto"
        flexDirection="column"
        id="escritorio"
      >
        <h1 className="title">ESCRITÓRIO</h1>
        <div id="escritorioBody">
          <div id="escritorioTxt">
            <p>
              Fundado em 2009, o escritório <b>CAMPOS & FARIAS – ADVOCACIA & CONSULTORIA</b> tem como objetivo oferecer um atendimento personalizado a seus clientes, proporcionando-lhes assistência jurídica com elevado padrão de qualidade. 
              <br />
              <br />
              O escritório conta com uma vasta experiência no ramo do Direito Público, notadamente Direito Administrativo, com ênfase em licitações e contratos administrativos, e Direito Tributário, com foco na orientação do planejamento tributário do cliente, resposta a consultas mediante parecer e atuação no contencioso administrativo e judicial.
              <br />
              <br />
              Além disso, também possui atuação destacada no Direito Privado, especialmente no Direito Civil, do Consumidor e Empresarial, principalmente na elaboração e análise de contratos diversos.
            </p>
          </div>
          <div>
            <img id="imgLocation" src={location} alt="location" />
            <h3 id="endereco">
              Rua Durval Guimarães, nº 1217
              <br />
              Empresarial Leonardo da Vinci, Sala 103
              <br />
              Ponta Verde, CEP: 57.035-060
              <br />
              Maceió/AL
            </h3>
          </div>
        </div>
        <Box py={8} mx="auto">
          <div id="mapa" dangerouslySetInnerHTML={iframe} />
        </Box>
      </Box>
      <Box
        bgcolor="var(--main-escuro)"
        color="var(--main-claro)"
        py={4}
        mx="auto"
        flexDirection="column"
        id="socios"
      >
        <h1 className="title">SÓCIOS</h1>
        <article>
          <div className="fotoenome">
            <img id="fotoSocios" src={contacts} alt="fotoSocios" />
            <h2>RODRIGO ARAÚJO CAMPOS</h2>
            <h5 className='emailContato'>
              <a href="mailto:rodrigo@camposefarias.com.br">
                rodrigo@camposefarias.com.br
              </a>
            </h5>
            <h4>
              <a href="https://wa.me/5582999836407">
                (82) 9.9983-6407
              </a>
            </h4>
          </div>
          <p className="txtSocios">
            Graduado em Direito pela Universidade Federal de Alagoas – UFAL
            (2007); Pós-Graduado em Direito Civil e Processual Civil pela Escola
            Superior de Advocacia de Alagoas – ESA/AL (2011); Procurador do
            Município de Traipu/AL (2013/2016); Assessor Jurídico da Câmara
            Municipal de Viçosa/AL (01/2014 a 02/2014); Assessor Especial da
            Comissão Permanente de Licitação (CPL) do Município de Rio Largo/AL
            (07/2014 a 11/2014); Procurador-Geral do Município de Porto de
            Pedras/AL (01/2017 a 05/2018); Procurador-Geral Adjunto do Município
            de Arapiraca/AL (06/2018 a 08/2020); Advogado inscrito na OAB/AL sob
            o n.º 8.544.
          </p>
        </article>
        <article id="socioInverte">
          <div className="fotoenome">
            <img id="fotoSocios" src={contacts} alt="fotoSocios" />
            <h2>LUIZ ROBERTO BARROS FARIAS</h2>
            <h5 className='emailContato'>
              <a href="mailto:luiz@camposefarias.com.br">
                luiz@camposefarias.com.br
              </a>
            </h5>
            <h4>
              <a href="https://wa.me/5582999690377">
                (82) 9.9969-0377
              </a>
            </h4>
          </div>
          <p className="txtSocios" id="txtInvertido">
            Graduado em Direito pela Universidade Federal de Alagoas – UFAL
            (2007); Especialista em Direito Tributário (2011); Mestre em Direito Público pela
            Universidade Federal de Alagoas – UFAL (2017); Capacitado pela
            Escola Nacional de Administração Pública – ENAP no Curso SICONV Para
            Convenentes (Módulos 1, 2 e 3); Procurador efetivo do Município de
            Arapiraca/AL (desde 2014); Coordenador da Subprocuradoria de
            Assuntos Tributários do Município Arapiraca/AL (desde 2017);
            Advogado inscrito na OAB/AL sob o n.º 8.740.
          </p>
        </article>
      </Box>
      <Box
        bgcolor="var(--main-claro)"
        color="var(--main-escuro)"
        py={4}
        mx="auto"
        id="contatoWrapper"
      >
        <h1 className="title" id="contato">
          CONTATO
        </h1>
        <div id="contatoBody">
          <div id="contatoCards">
            <Box
              bgcolor="#dbdad5"
              color="var(--main-escuro)"
              borderRadius={"2em"}
              className="contatoBox"
            >
              <img src={tel} id="tel" alt="telefone" />
              <h2 id="numTel">(82)3313-7704</h2>
            </Box>
            <Box
              bgcolor="#dbdad5"
              color="var(--main-escuro)"
              borderRadius={"2em"}
              className="contatoBox"
            >
              <img src={email} id="email" alt="email" />
              <h3>
                <a
                  href="mailto:contato@camposefarias.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contato@<br></br>camposefarias.com.br
                </a>
              </h3>
            </Box>
          </div>
          <div id="formComTitulo">
            <h2>FALE CONOSCO</h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                id="standard-basic"
                label="Nome"
                defaultValue=" "
                margin="normal"
                inputRef={register}
                name="nome"
              />
              {errors.nome && <span>Este campo é obrigatório</span>}
              <TextField
                required
                id="standard-basic"
                label="E-mail"
                defaultValue=" "
                margin="normal"
                inputRef={register}
                name="email"
              />
              {errors.email && <span>Este campo é obrigatório</span>}
              <TextField
                multiline
                required
                id="outlined-multiline-static"
                label="Mensagem"
                rows={4}
                defaultValue=" "
                margin="normal"
                inputRef={register}
                name="msg"
              />
              {errors.msg && <span>Este campo é obrigatório</span>}
              <ColorButton variant="contained" color="primary">
                ENVIAR
              </ColorButton>
            </form>
          </div>
        </div>
      </Box>
      <Box
        id="footer"
        bgcolor="var(--main-escuro)"
        color="var(--main-claro)"
        flexDirection="column"
        py={4}
      >
        <img id="logoFooter" src={logoBG} alt="Logo" />
        <div id="contatoFooter">
          <div className="infoFooter">
            <h4>Endereço</h4>
            <p>
              Av. Durval Guimarães, 1217
              <br />
              Empresarial Leonardo da Vinci, sala 103
              <br />
              Ponta Verde, Maceió/AL <br />
              CEP 57035-060
            </p>
          </div>
          <div className="infoFooter">
            <h4>Telefone</h4>
            <p>
              (82) 3313-7704
              <br />
              <a
                href="https://wa.me/5582999690377"
                target="_blank"
                rel="noopener noreferrer"
              >
                (82) 9.9969-0377
              </a>
              <br />
              <a
                href="https://wa.me/5582999836407"
                target="_blank"
                rel="noopener noreferrer"
              >
                (82) 9.9983-6407
              </a>
            </p>
          </div>
          <div className="infoFooter">
            <h4>E-mail</h4>
            <p>
              <a
                href="mailto:contato@camposefarias.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                contato@camposefarias.com.br
              </a>
            </p>
          </div>
        </div>
      </Box>
    </div>
  )
}
